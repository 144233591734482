import { initClient } from '@sunrise/client'
import { defineAsyncComponent } from 'vue'
import { initHotReload } from './hotReload'

const dynamicImports = {
    MsPushNotification: defineAsyncComponent(() => import('./components/MsPushNotifications/MsPushNotification.vue')),
    MsTwitterEmbedded: defineAsyncComponent(() => import('./components/MsTwitterEmbedded/MsTwitterEmbedded.vue')),
    HydratedLocalInformationNewsletterRegistration: defineAsyncComponent(
        () =>
            import(
                './cmsElements/M/M_Local_Information_Newsletter_Registration/HydratedLocalInformationNewsletterRegistration/HydratedLocalInformationNewsletterRegistration.vue'
            ),
    ),
    MsSocialMediaShare: defineAsyncComponent(() => import('./components/MsSocialMediaShare/MsSocialMediaShare.vue')),
    HydratedLogarithmicPagination: defineAsyncComponent(
        () => import('./components/HydratedLogarithmicPagination/HydratedLogarithmicPagination.vue'),
    ),
    HydratedRssFeedResultlist: defineAsyncComponent(
        () => import('./cmsElements/M/M_RssFeed_Resultlist/HydratedRssFeedResultlist/HydratedRssFeedResultlist.vue'),
    ),
    HydratedNewsFeedResultlist: defineAsyncComponent(
        () =>
            import(
                './cmsElements/M/M_Combined_News_Resultlist/HydratedNewsFeedResultlist/HydratedNewsFeedResultlist.vue'
            ),
    ),
    HydratedWeatherCityBox: defineAsyncComponent(
        () => import('./cmsElements/E/E_WeatherCityBox/HydratedWeatherCityBox/HydratedWeatherCityBox.vue'),
    ),
    LocalInformationStageRelocationForm: defineAsyncComponent(
        () =>
            import(
                './cmsElements/M/M_LI_Relocation_Stage/LocalInformationStageRelocationForm/LocalInformationStageRelocationForm.vue'
            ),
    ),
    HydratedPoliceTileResultList: defineAsyncComponent(
        () =>
            import(
                './cmsElements/M/M_Police_Tile_Result_List/HydratedPoliceTileResultList/HydratedPoliceTileResultList.vue'
            ),
    ),
    HydratedHeroLocalInformation: defineAsyncComponent(
        () =>
            import(
                './cmsElements/M/M_HeroLocalInformation/HydratedHeroLocalInformation/HydratedHeroLocalInformation.vue'
            ),
    ),
    HydratedExpiredNotification: defineAsyncComponent(
        () =>
            import(
                './cmsElements/M/M_PoliceReport_Resultlist/HydratedExpiredNotification/HydratedExpiredNotification.vue'
            ),
    ),
}

initClient({
    adsEnabled: true,
    dynamicImports,
})

initHotReload()
